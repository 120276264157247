<template>
    <div class="liaison">
        <ul class="liaisonList clear marginT40">
            <li class="clear" v-for="(item, index) in resList" :key="index">
                <div class="fl liaisonList_l">
                    <div class="liaisonList_l_bg">
                        <img :src="item.imgsrc" :alt="item.text" />
                        <div class="liaisonList_l_txt">
                            {{ item.text }}
                        </div>
                    </div>
                </div>
                <div class="fl liaisonList_r ">
                    <div
                        class="liaisonList_rcon"
                        v-for="(item1, index1) in item.userList"
                        :key="index1 + 'item1'"
                    >
                        <div class="lialine lialine1">
                            <img src="../../assets/images/contactus/icon2.png" />
                            <span><i>职能：</i>{{ item1.function }}</span>
                        </div>
                        <div class="lialine lialine2">
                            <img src="../../assets/images/contactus/icon4.png" />
                            <span><i>手机：</i>{{ item1.mobilePhone }}</span>
                        </div>
                        <div class="lialine lialine3">
                            <img src="../../assets/images/contactus/icon3.png" />
                            <span><i>座机：</i>{{ item1.landline }}</span>
                        </div>
                    </div>
                </div>
            </li>
        </ul>
    </div>
</template>
<script>
export default {
    data() {
        return {
            dataList: [
                {
                    imgsrc: require("../../assets/images/contactus/liaison1.png"),
                    text: "工厂运维服务联系人",
                    userList: [
                        {
                            function: "包装",
                            mobilePhone: "13580426038",
                            landline: "020－84953033"
                        },
                        {
                            // name: '邝家涛',
                            function: "检修",
                            mobilePhone: "13711419121",
                            landline: "020－84953033"
                        },
                        {
                            // name: '黄金建',
                            function: "车队",
                            mobilePhone: "13725278212",
                            landline: "020－84953033"
                        }
                    ]
                },
                {
                    imgsrc: require("../../assets/images/contactus/liaison2.png"),
                    text: "城市综合体运维联系人",
                    userList: [
                        {
                            // name: '杨焯南',
                            function: "运维",
                            mobilePhone: "13632464904",
                            landline: "020－84953033"
                        }
                    ]
                },
                {
                    imgsrc: require("../../assets/images/contactus/liaison3.png"),
                    text: "城市综合管廊运维联系人",
                    userList: [
                        {
                            // name: '成京辉',
                            function: "保障",
                            mobilePhone: "13725288710",
                            landline: "020-88906319"
                        }
                    ]
                },
                {
                    imgsrc: require("../../assets/images/contactus/liaison4.png"),
                    text: "产业园区服务联系人",
                    userList: [
                        {
                            // name: '郑鹏勇',
                            function: "物业管理",
                            mobilePhone: "13903066313",
                            landline: "020-88906479"
                        }
                    ]
                },
                {
                    imgsrc: require("../../assets/images/contactus/liaison5.png"),
                    text: "工业园区服务联系人",
                    userList: [
                        {
                            // name: '袁金彪',
                            function: "物业管理",
                            mobilePhone: "15521183168",
                            landline: "020-84772133"
                        }
                    ]
                },
                {
                    imgsrc: require("../../assets/images/contactus/liaison6.png"),
                    text: "高端写字楼服务联系人",
                    userList: [
                        {
                            // name: '郭朝晖',
                            function: "物业管理",
                            mobilePhone: "13500009945",
                            landline: "020-81502140"
                        }
                    ]
                },
                {
                    imgsrc: require("../../assets/images/contactus/liaison7.png"),
                    text: "生活服务联系人",
                    userList: [
                        {
                            // name: '黄伟坚',
                            function: "推广",
                            mobilePhone: "13318815565",
                            landline: "020-88906516"
                        }
                    ]
                },
                {
                    imgsrc: require("../../assets/images/contactus/liaison8.png"),
                    text: "在线商城联系人",
                    userList: [
                        {
                            // name: '黄伟坚',
                            function: "运营",
                            mobilePhone: "18670705581",
                            landline: "020-88906516"
                        }
                    ]
                }
            ],
            resList: []
        };
    },
    mounted() {
        this.getDataList();
    },
    methods: {
        async getDataList() {
            let res = await this.$request.getInfoBusinessContactList();
            let dataList = [];
            let dataArr = [];
            if (res.code === 200) {
                dataList = res.rows;
                dataList.map(resItem => {
                    if (dataArr.length == 0) {
                        dataArr.push({
                            imgsrc:
                                this.$store.state.baseUrl + resItem.imageUrl1,
                            text: resItem.businessTypeName,
                            userList: [resItem]
                        });
                    } else {
                        let res = dataArr.some(item => {
                            //判断相同的部门，有就添加到当前项
                            if (item.text == resItem.businessTypeName) {
                                item.userList.push(resItem);
                                return true;
                            }
                        });
                        if (!res) {
                            //如果没找相同的部门添加一个新对象
                            dataArr.push({
                                imgsrc:
                                    this.$store.state.baseUrl +
                                    resItem.imageUrl1,
                                text: resItem.businessTypeName,
                                userList: [resItem]
                            });
                        }
                    }
                });
            }
            this.resList = dataArr;
            // console.log(this.resList);
        }
    }
};
</script>
<style lang="scss">
.liaison {
    width: 64vw;
    margin: 0 auto;
}
.liaisonList {
    li {
        max-width: 100%;
        float: left;
        margin: 0 0.6vw;
        // &:nth-child(3),
        // &:nth-child(5),
        // &:nth-child(7) {
        //     margin-left: 1.3021vw;
        // }
    }
    .liaisonList_l {
        width: 14.8438vw;
        min-height: 8.5938vw;
        overflow: hidden;

        .liaisonList_l_bg {
            width: 100%;
            height: 8.44vw;
            position: relative;
            overflow: hidden;
            img {
                width: 100%;
            }

            .liaisonList_l_txt {
                width: 11.0938vw;
                height: 1.8229vw;
                line-height: 1.8229vw;
                text-align: center;
                font-size: 0.9375vw;
                color: #ffffff;
                font-weight: 500;
                letter-spacing: 0.1042vw;
                background-color: #f3ca00;
                position: absolute;
                top: 50%;
                left: 0;
                transform: translateY(-50%);
            }
        }
    }

    .liaisonList_r {
        max-width: 46.3542vw;
        width: auto;
        margin-left: 1.0417vw;
        font-size: 0;
        word-spacing: -0.0521vw;

        .liaisonList_rcon {
            width: 14.5833vw;
            height: 8.4896vw;
            margin-right: 1.3021vw;
            margin-bottom: 1.3021vw;
            box-sizing: border-box;
            display: inline-block;
            background-color: #ffffff;
            padding: 1.0417vw;
            box-sizing: border-box;
            box-shadow: 0vw 0.1042vw 0.2604vw 0vw rgba(0, 0, 0, 0.16);
            position: relative;
            &:last-child {
                margin-right: 0;
            }

            &::after {
                display: block;
                content: "";
                width: 0.2083vw;
                height: 2.0833vw;
                background-color: #f3ca00;
                position: absolute;
                bottom: 0.7813vw;
                left: -0.2083vw;
                border-radius: 0.1042vw;
            }

            .lialine {
                height: 2.1875vw;
                line-height: 2.1875vw;
                font-size: 0.8333vw;
                display: inline-block;
                &.lialine1 img {
                    margin-right: 1.3vw;
                    width: 0.89vw;
                }
                &.lialine2 img {
                    width: 1.15vw;
                }
                &.lialine3 img {
                    width: 1.09vw;
                }
                img {
                    margin-right: 1.0417vw;
                    vertical-align: sub;
                    display: inline-block;
                }
                span {
                    font-size: 0.73vw;
                }
            }
        }
    }
}
@media (min-width: 1920px) {
    .liaison {
        width: 1200px;
        margin: 0 auto;
    }
    .liaisonList {
        li {
            max-width: 100%;
            float: left;
            margin: 0 7px;
            // &:nth-child(3),
            // &:nth-child(5),
            // &:nth-child(7) {
            //     margin-left: 25px;
            // }
        }
        .liaisonList_l {
            width: 285px;
            min-height: 165px;
            overflow: hidden;

            .liaisonList_l_bg {
                width: 100%;
                height: 162px;
                position: relative;

                img {
                    width: 100%;
                }

                .liaisonList_l_txt {
                    width: 220px;
                    height: 35px;
                    line-height: 35px;
                    text-align: center;
                    font-size: 17px;
                    color: #ffffff;
                    font-weight: 500;
                    letter-spacing: 2px;
                    background-color: #f3ca00;
                    position: absolute;
                    top: 50%;
                    left: 0;
                    transform: translateY(-50%);
                }
            }
        }

        .liaisonList_r {
            width: auto;
            max-width: 890px;
            margin-left: 20px;
            font-size: 0;
            word-spacing: -1px;

            .liaisonList_rcon {
                width: 280px;
                height: 163px;
                margin-right: 20px;
                margin-bottom: 25px;
                box-sizing: border-box;
                display: inline-block;
                background-color: #ffffff;
                padding: 20px;
                box-sizing: border-box;
                box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.16);
                position: relative;

                &:last-child {
                    margin-right: 0;
                }

                &::after {
                    display: block;
                    content: "";
                    width: 4px;
                    height: 40px;
                    background-color: #f3ca00;
                    position: absolute;
                    bottom: 15px;
                    left: -4px;
                    border-radius: 2px;
                }

                .lialine {
                    height: 42px;
                    line-height: 42px;
                    font-size: 16px;
                    display: inline-block;
                    &.lialine1 img {
                        margin-right: 25px;
                        width: 17px;
                    }
                    &.lialine2 img {
                        width: 22px;
                    }
                    &.lialine3 img {
                        width: 21px;
                    }
                    img {
                        margin-right: 20px;
                        vertical-align: sub;
                        display: inline-block;
                    }
                }
            }
        }
    }
}
</style>
