var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"banner"},[_vm._t("default",function(){return [_c('swiperCpt',{ref:"swiperBanner",staticClass:"swiperBanner",attrs:{"slidesPerView":1,"loop":false,"delay":3000,"autoplay":true,"sid":"swiperBanner"}},_vm._l((_vm.banner_img),function(item,index){return _c('div',{key:'swiperBanner' + index,staticClass:"swiper-slide"},[(item.skipHref)?_c('router-link',{staticClass:"hrefLink",attrs:{"to":item.skipHref}}):_vm._e(),_c('img',{staticClass:"animated fadeInUpBanner",attrs:{"src":_vm.$store.state.baseUrl + item.imageUrl}}),_c('div',{staticClass:"crilebg fadeInUp1 animated"},[_c('div',{staticClass:"crilebg_cn",style:({
              'animation-delay': 1 * 0.6 + 's',
              '-webkit-animation-delay': 1 * 0.6 + 's',
            })},[_vm._v(" "+_vm._s(_vm.banner_cn)+" ")]),_c('div',{staticClass:"crilebg_en",style:({
              'animation-delay': 1 * 0.6 + 's',
              '-webkit-animation-delay': 1 * 0.6 + 's',
            })},[_vm._v(" "+_vm._s(_vm.banner_en)+" ")])])],1)}),0)]})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }