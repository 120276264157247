<template>
  <div class="mainCont mainContactus clear on">
    <div class="mainForm">
      <div class="box-info">
        <div class="box-item box-item01">
          <div class="box-icon box-icon01"></div>
          <span> 地址: {{ Enterprise.enterpriseAddress }} </span>
        </div>
        <div class="box-item box-item02">
          <div class="box-icon box-icon02"></div>
          <span> 邮箱: {{ Enterprise.enterpriseEmail }}</span>
        </div>
        <div class="box-item box-item03">
          <div class="box-icon box-icon03"></div>
          <span> 联系电话：{{ Enterprise.enterprisePhone }} </span>
        </div>
      </div>
      <!-- <form id="form">
        <input
          class="form-input"
          type="text"
          v-model="postData.createUser"
          placeholder="您的名字："
        />
        <input
          class="form-input"
          type="text"
          v-model="postData.onlinePhone"
          placeholder="您的手机号码："
        />
        <textarea
          class="form-textarea"
          rows="10"
          cols="30"
          v-model="postData.onlineContent"
          placeholder="您的需求："
        >
        </textarea>
        <div class="form-submit" @click="submitCon()">提交</div>
      </form> -->
    </div>
    <div class="m-cont m-cont-left"></div>
    <div class="m-cont m-cont-right" id="container">
      <!-- <el-amap
        vid="amapDemo"
        style="height: 600px"
        :center="center"
        :amap-manager="amapManager"
        :zoom="zoom"
        class="amap-demo"
      >
        <el-amap-marker :position="center"></el-amap-marker>

        <el-amap-info-window
          :position="center"
          :offset="[0, 0]"
          auto-move
          is-custom
        >
          <div class="info-window-content">
            <b>{{ Enterprise.enterpriseName }}</b>
            <p>电话：{{ Enterprise.enterprisePhone }}</p>
            <p>地址：{{ Enterprise.enterpriseAddress }}</p>
          </div>
        </el-amap-info-window>
      </el-amap> -->
    </div>
    <div class="model" v-show="isShow" @click="isShow = false"></div>
    <div class="model_con" v-show="isShow">
      <h3 class="title">请输入验证码</h3>
      <div class="line">
        <input placeholder="请输入验证码" v-model="postData.code" />
        <img :src="chaObj.img ? url + chaObj.img : ''" @click="chaImg" />
      </div>
      <el-button type="primary" class="mainBtn" @click="addLine">
        确定
      </el-button>
    </div>
  </div>
</template>

<script>
import { Loading } from "element-ui";
// import VueAMap from "vue-amap";
// import MapLoader from "@/plugins/aMap.js";

// let amapManager = new VueAMap.AMapManager();
export default {
  data() {
    return {
      url: "data:image/gif;base64,",
      chaObj: "",
      postData: {
        createUser: "",
        onlinePhone: "",
        onlineContent: "",
        code: "",
        uuid: "",
      },
      isShow: false,
      // center: [113.217752, 23.043346],
      // zoom: 16, //缩放级别
      // amapManager,
      Enterprise: {
        enterpriseName: "广州工控服务管理有限公司",
        enterprisePhone: "020-88906327",
        enterpriseAddress: "广州市荔湾区花地大道南657号岭南V谷C1栋5楼",
        enterpriseEmail: "gkfw@giihg.com",
      },
    };
  },
  mounted() {
    // this.submitCon()
    // loadingInstance.close();
    // Loading.service()
    // this.chaImg();
    // this.getEnterprisePage();
  },
  methods: {
    async getEnterprisePage() {
      let res = await this.$request.getEnterprisePage();
      let dataList = [];
      if (res.code === 200) {
        dataList = await res.rows.filter((item) => {
          return item.enterpriseName == "广州工控服务管理有限公司";

          // console.log(resItem)
          // console.log(dataItem);
        });
      }
      this.Enterprise = dataList[0];
      // console.log(dataList[0]);
    },
    async chaImg() {
      let imgobj = await this.$request.getChaimg();
      this.chaObj = imgobj;
      this.postData.uuid = imgobj.uuid;
    },
    addLine() {
      if (!this.postData.code) {
        this.$alert("请输入验证码", "温馨提示", {
          confirmButtonText: "确定",
        });
        return;
      }
      let loadingInstance = Loading.service();
      //联系方式
      this.$request.postaddInfo(this.postData).then((res) => {
        loadingInstance.close();
        if (res.code == "200") {
          this.$message.warning("您的需求，已提交成功");
          // console.log("提交成功")
          this.isShow = false;
        } else {
          this.$message.warning(res.msg);
        }
      });
    },
    checkMobile(s) {
      var regu = /^1[3456789]\d{9}$/;
      var re = new RegExp(regu);
      if (re.test(s)) {
        return true;
      } else {
        return false;
      }
    },
    submitCon() {
      if (!this.postData.createUser) {
        this.$alert("请输入您的名字", "温馨提示", {
          confirmButtonText: "确定",
        });
        return;
      }
      if (!this.postData.onlinePhone) {
        this.$alert("请输入您的手机号码", "温馨提示", {
          confirmButtonText: "确定",
        });
        return;
      } else {
        if (
          !this.checkMobile(this.postData.onlinePhone.replace(/(^\s*)/g, ""))
        ) {
          this.$alert("请输入正确的手机号码", "温馨提示", {
            confirmButtonText: "确定",
          });
          return false;
        }
      }
      if (!this.postData.onlineContent) {
        this.$alert("请输入您的需求", "温馨提示", {
          confirmButtonText: "确定",
        });
        return;
      }
      // 更新验证码
      this.chaImg();

      this.isShow = true;
    },
  },
};
</script>

<style lang="scss" scoped>
.amap-demo {
  position: relative;
  z-index: 99;
  .info-window-content {
    b {
      font-size: 0.83vw;
      display: inline-block;
      padding: 10px 0;
    }
    p {
      font-size: 0.83vw;
    }
  }
}
.mainContactus {
  height: 600px;
  position: relative;
  margin: 1.5625vw 0;
  .mainForm {
    display: flex;
    align-items: center;
    width: 62.5vw;
    position: absolute;
    top: 0;
    left: 50%;
    margin: 0 0 0 -31.25vw;
    height: 31.25vw;
    z-index: 22;
  }
  .box-info {
    // width: 24.4792vw;
    width: 29.17vw;
    padding-top: 1.15vw;
  }

  .box-item {
    position: relative;
    height: 3.0208vw;
    line-height: 3.0208vw;
    padding: 0.5208vw 0 0.5208vw 4.1667vw;

    .box-icon {
      width: 3.0208vw;
      height: 3.0208vw;
      position: absolute;
      top: 50%;
      left: 0;
      margin: -1.5104vw 0 0 0;

      &.box-icon01 {
        background: url("../../assets/images/contactus/address.png") left bottom
          no-repeat;
        background-size: cover;
      }

      &.box-icon02 {
        background: url("../../assets/images/contactus/email.png") left bottom
          no-repeat;
        background-size: cover;
      }

      &.box-icon03 {
        background: url("../../assets/images/contactus/phone.png") left bottom
          no-repeat;
        background-size: cover;
      }
    }

    span {
      display: inline-block;
      color: #fff;
      font-size: 0.83vw;
      line-height: 2vw;
      border-bottom: 0.0521vw solid #f3ca00;
    }
  }
}

.m-cont {
  float: left;
  width: 50%;
  height: 600px;

  &.m-cont-left {
    background: url("../../assets/images/contactus/beijing.png") left bottom
      no-repeat;
    background-size: cover;
  }

  &.m-cont-right {
    overflow: hidden;
    background: url("../../assets/images/contactus/ditu.png") left bottom
      no-repeat;
    background-size: cover;
  }
}

#form {
  min-width: 22.8125vw;
  position: relative;

  .form-input,
  .form-textarea,
  .form-submit {
    border-radius: 0.2604vw;
    padding: 0.5208vw;
    outline: none;
  }

  .form-input {
    border: none;
    display: inline-block;
    width: 9.8958vw;
    margin: 0.2604vw 0.0521vw;
    padding: 0.5208vw 0.5208vw;
    border: solid 0.1042vw #f3ca00;
    font-size: 0.83vw;
  }

  .form-textarea {
    border: none;
    display: block;
    width: 21.4583vw;
    font-size: 0.83vw;
    margin: 0.2604vw 0 0.5208vw;
    border: solid 0.1042vw #f3ca00;
    font-family: Noto Sans SC, arial, helvetica, clean, sans-serif;
    font-size: 0.8333vw;
  }

  .form-submit {
    border: none;
    width: 22.7083vw;
    height: 2.1354vw;
    font-size: 0.83vw;
    text-align: center;
    background-color: #f3ca00;
    border-radius: 0.2604vw;
    cursor: pointer;
    box-sizing: border-box;
  }
}

.model {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  background: rgba(0, 0, 0, 0.5);
}

.model_con {
  position: fixed;
  z-index: 1000;
  top: 50%;
  left: 50%;
  width: 20.8333vw;
  border-radius: 0.3125vw;
  padding: 1.3021vw 1.3021vw 1.3021vw 1.3021vw;
  transform: translate(-50%, -50%);
  box-sizing: border-box;
  background: #ffffff;

  .title {
    text-align: center;
    color: #707070;
    font-size: 0.975vw;
    font-weight: 600;
    margin-bottom: 1.5625vw;
  }

  input {
    width: 10.4167vw;
    height: 1.9792vw;
    border-radius: 0.2604vw;
    border: 0.0521vw solid #dcdfe6;
    margin-right: 1.0417vw;
    outline: none;
    padding-left: 1.0417vw;
    font-size: 0.9375vw;
    box-sizing: border-box;
  }

  img {
    width: 5.9896vw;
    display: inline-block;
    vertical-align: middle;
    cursor: pointer;
  }
}

.mainBtn {
  width: 100%;
  margin-top: 1.5625vw;
}

.el-button--primary {
  background: #f39800;
  border: none;
}

.el-button--primary:focus,
.el-button--primary:hover {
  background: #f39800;
  border: none;
}

@media (min-width: 1920px) {
  .mainContactus {
    height: 600px;
    position: relative;
    margin: 30px 0;
    .mainForm {
      width: 1200px;
      display: flex;
      align-items: center;
      margin: 0 auto;
      position: absolute;
      top: 0;
      left: 50%;
      margin: 0 0 0 -600px;
      z-index: 100;
    }
    .box-info {
      width: 600px;
      padding-top: 22px;
      .box-item {
        position: relative;
        height: 58px;
        line-height: 58px;
        padding: 10px 0 10px 80px;

        .box-icon {
          width: 58px;
          height: 58px;
          position: absolute;
          top: 50%;
          left: 0;
          margin: -29px 0 0 0;

          &.box-icon01 {
            background: url("../../assets/images/contactus/address.png") left
              bottom no-repeat;
            background-size: cover;
          }

          &.box-icon02 {
            background: url("../../assets/images/contactus/email.png") left
              bottom no-repeat;
            background-size: cover;
          }

          &.box-icon03 {
            background: url("../../assets/images/contactus/phone.png") left
              bottom no-repeat;
            background-size: cover;
          }
        }

        span {
          display: inline-block;
          color: #fff;
          line-height: 24px;
          border-bottom: 1px solid #f3ca00;
        }
      }
    }
  }

  .m-cont {
    float: left;
    width: 50%;
    height: 600px;
    position: relative;
    &.m-cont-left {
      background: url("../../assets/images/contactus/beijing.png") left bottom
        no-repeat;
      background-size: cover;
    }

    &.m-cont-right {
      overflow: hidden;
      background: url("../../assets/images/contactus/ditu.png") left bottom
        no-repeat;
      background-size: cover;
    }
  }

  #form {
    min-width: 438px;
    position: relative;

    .form-input,
    .form-textarea,
    .form-submit {
      border-radius: 5px;
      padding: 10px;
      outline: none;
    }

    .form-input {
      border: none;
      display: inline-block;
      width: 190px;
      margin: 5px 1px;
      padding: 10px 10px;
      border: solid 2px #f3ca00;
    }

    .form-textarea {
      border: none;
      display: block;
      width: 412px;
      margin: 5px 0 10px;
      border: solid 2px #f3ca00;
      font-family: Noto Sans SC, arial, helvetica, clean, sans-serif;
      font-size: 16px;
    }

    .form-submit {
      border: none;
      width: 436px;
      height: 41px;
      text-align: center;
      background-color: #f3ca00;
      border-radius: 5px;
      cursor: pointer;
      box-sizing: border-box;
    }
  }

  .model {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1000;
    background: rgba(0, 0, 0, 0.5);
  }

  .model_con {
    position: fixed;
    z-index: 1000;
    top: 50%;
    left: 50%;
    width: 400px;
    border-radius: 6px;
    padding: 25px 25px 25px 25px;
    transform: translate(-50%, -50%);
    box-sizing: border-box;
    background: #ffffff;

    .title {
      text-align: center;
      color: #707070;
      font-size: 18.72px;
      font-weight: 600;
      margin-bottom: 30px;
    }

    input {
      width: 200px;
      height: 38px;
      border-radius: 5px;
      border: 1px solid #dcdfe6;
      margin-right: 20px;
      outline: none;
      padding-left: 20px;
      font-size: 18px;
      box-sizing: border-box;
    }

    img {
      width: 115px;
      display: inline-block;
      vertical-align: middle;
      cursor: pointer;
    }
  }

  .mainBtn {
    width: 100%;
    margin-top: 30px;
  }

  .el-button--primary {
    background: #f39800;
    border: none;
  }

  .el-button--primary:focus,
  .el-button--primary:hover {
    background: #f39800;
    border: none;
  }
}
</style>
