<template>
  <div class="banner">
    <slot>
      <swiperCpt
        class="swiperBanner "
        ref="swiperBanner"
        :slidesPerView="1"
        :loop="false"
        :delay="3000"
        :autoplay="true"
        sid="swiperBanner"
      >
        <div
          class="swiper-slide"
          v-for="(item, index) in banner_img"
          :key="'swiperBanner' + index"
        >
          <router-link v-if="item.skipHref" class="hrefLink" :to="item.skipHref"></router-link>
          <img
            class="animated fadeInUpBanner"
            :src="$store.state.baseUrl + item.imageUrl"
          />
          <div class="crilebg fadeInUp1 animated">
            <div
              class="crilebg_cn "
              :style="{
                'animation-delay': 1 * 0.6 + 's',
                '-webkit-animation-delay': 1 * 0.6 + 's',
              }"
            >
              {{ banner_cn }}
            </div>
            <div
              class="crilebg_en "
              :style="{
                'animation-delay': 1 * 0.6 + 's',
                '-webkit-animation-delay': 1 * 0.6 + 's',
              }"
            >
              {{ banner_en }}
            </div>
          </div>
        </div>
      </swiperCpt>
    </slot>
  </div>
</template>

<script>
import swiperCpt from "@/components/common/swiperCpt.vue";
export default {
  props: ["banner_img", "banner_en", "banner_cn"],
  components: {
    swiperCpt,
  },
};
</script>

<style lang="scss" scoped>

.swiper-slide {
  position: relative;
  .hrefLink {
    display: block;
    width: 100%;
    height: 22.4vw;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
  }
}
.banner {
  width: 100%;
  // min-height: 22.4vw;
  position: relative;
  img {
    width: 100%;
    display: block;
  }
}

.crilebg {
  width: 5.9896vw;
  height: 5.9896vw;
  background: rgba(0, 0, 0, 0.08);
  border-radius: 50%;
  text-align: center;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-left: -2.9948vw;
  margin-top: -2.9948vw;
  .crilebg_en {
    position: absolute;
    font-size: 0.9375vw;
    color: #ffffff;
    width: 30.5208vw;
    left: 50%;
    top: 3.6458vw;
    margin-left: -15.2604vw;
  }
  .crilebg_cn {
    position: absolute;
    font-size: 1.5625vw;
    width: 26.7708vw;
    top: 1.5625vw;
    color: #fff;
    left: 50%;
    text-align: center;
    margin-left: -13.3854vw;
  }
}

@media (min-width: 1920px) {
  .banner {
    width: 100%;
    min-height: 430px;
    position: relative;
    img {
      width: 100%;
      display: block;
    }
    .crilebg {
      width: 115px;
      height: 115px;
      background: rgba(0, 0, 0, 0.08);
      border-radius: 50%;
      text-align: center;
      position: absolute;
      top: 50%;
      left: 50%;
      margin-left: -57.5px;
      margin-top: -57.5px;
      .crilebg_en {
        position: absolute;
        font-size: 18px;
        color: #ffffff;
        width: 586px;
        left: 50%;
        top: 70px;
        margin-left: -293px;
      }
      .crilebg_cn {
        position: absolute;
        font-size: 30px;
        width: 514px;
        top: 30px;
        color: #fff;
        left: 50%;
        text-align: center;
        margin-left: -257px;
      }
    }
  }
}
</style>
