<template>
    <div class="contain contactus">
        <Banner
            :banner_img="resBanners"
            banner_en="CONTACT US"
            banner_cn="联系我们"
        ></Banner>
        <div class="pnav clear">
            <div
                :class="[item.key === componentTag.key ? 'on' : '', 'navItem']"
                @click="OnChangeCont(item)"
                v-for="item in pnavList"
                :key="item.key"
            >
                <router-link
                    class="nuxtLink"
                    :to="'/contactus?key=' + item.key"
                >
                    {{ item.text }}
                </router-link>
            </div>
        </div>

        <component
            :is="componentTag.key"
            class="wow fadeInUp animated"
        ></component>
    </div>
</template>
<script>
import Contactus from "@/components/contactus/Contactus";
import liaison from "@/components/contactus/liaison";
import FriendshipLink from "@/components/contactus/FriendshipLink";
import LegalNotice from "@/components/contactus/LegalNotice";
import Banner from "@/components/common/Banner.vue";

export default {
    data() {
        return {
            componentTag: {
                index: 0,
                text: "联系我们",
                key: "Contactus"
            },
            pnavList: [
                {
                    index: 0,
                    text: "联系我们",
                    key: "Contactus"
                },
                {
                    index: 1,
                    text: "业务联系",
                    key: "liaison"
                },
                // {
                //   index: 2,
                //   text: "法律声明",
                //   key: "LegalNotice"
                // },
                {
                    index: 3,
                    text: "友情链接",
                    key: "FriendshipLink"
                }
            ],
            resBanners: []
        };
    },
    mounted() {
        new  this.$wow.WOW({
                live: false,
                offset: 0
            }).init()
        this.getDataList();
    },
    methods: {
        OnChangeCont(data) {
            this.componentTag = data;
        },
        async getDataList() {
            let res = await this.$request.getImageAdvertisingList({
                advertisingPosition: "7"
            });
            if (res.code === 200) {
                this.resBanners = []
                    this.resBanners = res.rows;
            }
        }
    },
    watch: {
        // 监听路由发生改变
        $route: {
            handler(newVal, oldval) {
                if (newVal != oldval && newVal.query.key) {
                    let resList = this.pnavList.filter(item => {
                        // console.log(newVal.query.key);
                        // console.log(item.key);
                        return newVal.query.key === item.key;
                    });
                    this.componentTag = resList[0];
                }
            },
            immediate: true
        }
    },
    components: {
        Contactus,
        FriendshipLink,
        LegalNotice,
        liaison,
        Banner
    }
};
</script>
<style lang="scss" scoped>
.contactus {
    .pnav {
        width: 62.5vw;
        .navItem {
            width: 33.3%;
        }
    }
}
@media (min-width: 1920px) {
.contactus {
    .pnav {
        width: 1200px;
        .navItem {
            width: 33.3%;
        }
    }
}
}

</style>
