<template>
  <div class="mainFriendshipLink mainBodyA clear mainCont">
    <div class="c-item" v-for="(item, index) in resList" :key="index + 'item1'">
      <a class="bg_link" target="_blank" :href="item.linkUrl">
        <img :src="$store.state.baseUrl + item.logo" :alt="item.linkName" />
      </a>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
    //   linkList: [
    //     {
    //       href: "#",
    //       imgsrc: require("../../assets/images/contactus/link01.png"),
    //       tips: "广州工控",
    //     },
    //     {
    //       href: "#",
    //       imgsrc: require("../../assets/images/contactus/link02.png"),
    //       tips: "万宝",
    //     },
    //     {
    //       href: "#",
    //       imgsrc: require("../../assets/images/contactus/link03.png"),
    //       tips: "万力轮胎",
    //     },
    //     {
    //       href: "#",
    //       imgsrc: require("../../assets/images/contactus/link04.png"),
    //       tips: "化工易",
    //     },
    //     {
    //       href: "#",
    //       imgsrc: require("../../assets/images/contactus/link05.png"),
    //       tips: "双一",
    //     },
    //   ],
      resList: [],
    };
  },
  mounted() {
    this.getDataList();
  },
  methods: {
    async getDataList() {
      let res = await this.$request.getFriendlyLinkList({ linkType: 0 });
      if (res.code === 200) {
        // console.log(res);
        this.resList = res.rows.filter((item) => item.linkType == "0");
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.mainFriendshipLink {
  min-height: 15.625vw;
  position: relative;
  padding: 1.56vw 0 1.56vw 0;
  .c-item {
    float: left;
    width: 10.42vw;
    height: 3.96vw;
    margin: 1.04vw 1.04vw;
    .bg_link {
      display: inline-block;
      width: 10.21vw;
      height: 3.96vw;
      position: relative;
      text-align: center;
      background: url("../../assets/images/common/bg_link.png") no-repeat;
      background-size: cover;
      img {
        display: inline-block;
        width: 7.29vw;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        max-height: 3.65vw;
      }
    }
  }
}
@media (min-width: 1920px) {
  /* 友情链接 */
  .mainFriendshipLink {
    min-height: 300px;
    position: relative;
    padding: 30px 0 30px 0;
    .c-item {
      float: left;
      width: 200px;
      height: 76px;
      margin: 20px 20px;
      .bg_link {
        display: inline-block;
        width: 196px;
        height: 76px;
        position: relative;
        text-align: center;
        background: url("../../assets/images/common/bg_link.png") no-repeat;
        background-size: cover;
        img {
          display: inline-block;
          width: 140px;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          max-height: 70px;
        }
      }
    }
  }
}
</style>
