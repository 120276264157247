<template>
  <div class="mainLegalNotice mainBodyA clear mainCont ">
    <div class="mCont-left"></div>
    <div class="mCont-right">
      <div class="m-text">
        文案需提供，文案需提供，文案需提供，文案需提供，文案需提供，文案需提供，文案需提供，文案需提供，文案需提供，文案需提供，文案需提供，文案需提供，文案需提供，文案需提供，文案需提供，文案需提供，文案需提供，文案需提供，文案需提供，文案需提供，文案需提供，文案需提供，文案需提供，文案需提供，文案需提供，文案需提供，文案需提供，文案需提供，文案需提供，文案需提供，文案需提供，文案需提供，文案需提供，文案需提供，文案需提供，文案需提供，文案需提供，文案需提供。
      </div>
    </div>
  </div>
</template>

<script>
  export default {

  }
</script>

<style lang="scss" scoped>
  .mainLegalNotice {
    padding: 2.0833vw 0 3.125vw;
    position: relative;
    .mCont-left {
      width: 18.9583vw;
      height: 12.6563vw;
      position: absolute;
      top: 2.0833vw;
      left: 0;
      background: url("../../assets/images/contactus/peitu.png") no-repeat;
      background-size: cover;
    }
    .mCont-right {
      height: 12.6563vw;
      line-height: 1.25vw;
      padding: 0 0 0 18.9583vw;
      display: table;
      color: #333333;
      background-color: #eeeeee;
      .m-text {
        display: table-cell;
        vertical-align: middle;
        text-indent: 1.6667vw;
        font-size: 0.8333vw;
        padding: 0 1.5625vw;
      }
    }
  }
  @media (min-width: 1920px) {

    /* 法律声明 */
    .mainLegalNotice {
      padding: 40px 0 60px;
      position: relative;

      .mCont-left {
        width: 364px;
        height: 243px;
        position: absolute;
        top: 40px;
        left: 0;
        background: url("../../assets/images/contactus/peitu.png") no-repeat;
        background-size: cover;
      }

      .mCont-right {
        height: 243px;
        line-height: 24px;
        padding: 0 0 0 364px;
        display: table;
        color: #333333;
        background-color: #eeeeee;

        .m-text {
          display: table-cell;
          vertical-align: middle;
          text-indent: 32px;
          font-size: 16px;
          padding: 0 30px;
        }
      }
    }
  }
</style>